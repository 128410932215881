<template>
  <main @keyup="onKeyUp">
    <div class="container user-auth__container">
      <div class="user__body user-auth__body">
        <!-- Header -->
        <header class="user__header user-auth__header">
          <h1 class="user__title">{{ $t('user-portal.user_signup_title')}}</h1>
        </header>

        <section 
          class="user-auth__actions" 
          v-if="welcomeReward">
          <actions-button
            :appearance="$pepper.Appearance.SUBTLE"
            class="fill user-auth__action"
            icon-post="arrow-right"
            :href="{ name: 'sayl-user-portal.loyalty' }"
          >{{ $t('user-portal.subscribe_loyalty_incentive', { reward: welcomeReward, symbol: pointsSymbol, program: programName }) }}</actions-button>
        </section>

        <!-- Sign up -->
        <section class="user__login user-auth__login flow">
          <forms-subscribe
            :errors="errors"
            :intent="confirmRoute"
            v-model="user"
          />

          <actions-button
            :appearance="$pepper.Appearance.PRIMARY"
            class="fill"
            :disabled="loading"
            :loading="loading"
            @click="onSubscribe"
          >{{ $t('user-portal.action_confirm') }}</actions-button>

          <forms-label>{{ $t('user-portal.user_confirm_tc_optout') }}</forms-label>
        </section>

        <!-- Login -->
        <section class="user-auth__alt user__subscribe">
          <div class="user-auth__label">{{ $t('user-portal.user_subscribe_login') }}</div>

          <actions-button
            :href="loginRoute"
          >{{ $t('user-portal.action_signin') }}</actions-button>
        </section>
      </div>

      <div 
        class="user-auth__illustration" 
        v-if="banner">
        <img
          class="user-auth__figure²"
          :src="banner"
        />
      </div>

      <div 
        class="user-auth__illustration -placeholder" 
        :style="`--placeholder-image: url(${placeholder})`"
        v-if="!banner">
      </div>
    </div>
  </main>
</template>

<script>
import FormsSubscribe from '@/modules/user/components/forms/subscribe'
import MixinErrors from '@/helpers/errors'
import Config from '@/config'

export default {
  name: 'UserLoginView',

  inject: [
    '$embed',
    '$loyalty',
    '$user',
    '$localStorage',

    '$redirect',
  ],

  components: {
    FormsSubscribe
  },

  mixins: [
    MixinErrors
  ],

  data() {
    return {
      errors: {},
      key: 1,
      loading: false,
    }
  },

  computed: {
    loginRoute() {
      let name = this.$route.name.replace('signup', 'login')
      return { name }
    },

    banner() {
      let ret = null
      let banners = this.$basil.get(this.embed, 'banners', [])

      if(banners && banners.length > 0) {
        ret = banners[0]
      }

      return ret
    },

    confirmRoute() {
      return !this.$basil.isNil(this.program) ? 
        { name: 'sayl-user-portal.loyalty' } :
        { name: 'sayl-user-portal.home' }
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed')
    },

    placeholder() {
      return Config.cdn + 'statics/images/logos/resto/logomark.png'
    },

    pointsSymbol() {
      return this.$basil.get(this.program, 'points.symbol')
    },

    program() {
      return this.$loyalty.program
    },

    programName() {
      return this.$basil.get(this.program, 'name')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    welcomeReward() {
      let ret = this.$basil.get(this.program, 'rules.welcomeReward')
      return ret != null && ret > 0 ? ret : null
    }
  },

  methods: {
    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onSubscribe()
      }
    },

    async onSubscribe() {
      try {
        this.loading = true
        this.errors = {}

        await this.$user.subscribe({ 
          user: this.user, 
          password: this.user.password,
          redirect: this.$redirect.redirect, 
          targetEmbedId: this.$redirect.target
        })

        if(this.$redirect.redirect != null) {
          window.location.href = this.$redirect.redirect
        } else {
          this.$router.replace(this.confirmRoute).catch(() => {})
        }
      } catch(e) {
        this.handleErrors(e)
      } finally {
        this.loading = false
        this.key++
      }
    }
  },

  mounted() {
    this.errors = {}

    sessionStorage.removeItem('utmSource')
    sessionStorage.removeItem('utmMedium')
  }
}
</script>
